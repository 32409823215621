export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',
  prefillBackroundColor: 'var(-white)',
  prefilllColor: 'var(--black)',
  prefillBoxShadow: 'var(--form-shadow)',
  validatedBrandColor: 'var(--exg-add-button-bg)',
};
